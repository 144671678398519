const cognitoSettings = {
  REGION: "us-west-2",
  /*USER_POOL_ID: "us-west-2_v8zZp3Jvv",
  APP_CLIENT_ID: "3di3fus49n5ihda5lenlikb4po",*/
  USER_POOL_ID: "us-west-2_pDVw2CTHi",
  APP_CLIENT_ID: "5k3fje75imh7u8r2n2uk69v5bm",
  IDENTITY_POOL_ID: "us-west-2:11db2f7a-f307-4803-85ac-375413b4b0e3",
}

const dev = {
  cognito: cognitoSettings,
  apiGateway: {
    REGION: "us-west-2",
    URL: "https://dev-api.photos.castlerock.ai",
  },
  s3: {
    BUCKET: "crphotoapp-dev",
    REGION: "us-west-2",
  }
}

const prod =  {
  cognito: cognitoSettings,
  apiGateway: {
    REGION: "us-west-2",
    URL: "https://api.photos.castlerock.ai",
  },
  s3: {
    BUCKET: "crphotoapp",
    REGION: "us-west-2",
  }
}

export const APINAME="photos"

export default process.env.NODE_ENV === "development" ? dev : prod;
